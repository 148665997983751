import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from '../components/home/Home';
import History from '../components/monitor/history/History';
import AccessInventory from '../components/monitor/ai/AccessInventory';
import Nav from '../components/common/nav/Nav';
import ServiceExecution from '../components/test/ServiceExecution';
import Loading from '../components/common/loading/Loading';
import ErrorBody from '../components/common/error/ErrorBody';
import Catalog from '../components/monitor/catalog/Catalog';
import ServiceCatalog from '../components/monitor/catalog/ServiceCatalog';
import Sigma from '../components/monitor/sigma/Sigma';
import Resource from '../components/management/resource/Resource';
import Property from '../components/management/property/Property';
import Cache from '../components/management/cache/Cache';
import EnvInfo from '../components/management/envInfo/EnvInfo';
import Staging from '../components/monitor/staging/Staging';
import '../asset/css/app.css';
import {hasAccess, hasInternalAccess} from '../api/management_api';
import MaxSpeed from '../components/monitor/speed/MaxSpeed';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";
import Login from "../login/Login";
import Swagger from '../components/swagger/Swagger';
import Details from '../components/transaction/details/Details';
import DeltaInfo from '../components/transaction/delta/DeltaInfo';
import Retry from '../components/transaction/retry/Retry';
import { AuthContext } from '../context/AuthContext';
import { hasAdminWriteAccess } from '../api/management_api';

function App() {
    console.log('REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT);
    console.log('REACT_APP_BASEURL', process.env.REACT_APP_BASEURL);
    console.log('REACT_APP_AUTHORITY', process.env.REACT_APP_AUTHORITY);
    console.log('REACT_APP_REDIRECT', process.env.REACT_APP_REDIRECT);

    return (
        <BrowserRouter>
            <AuthenticatedTemplate>
                <Nav>
                    <Routes>
                        <Route path='/home' element={<Home/>}/>
                        <Route path='/monitor/history' element={<History/>}/>
                        <Route path='/monitor/ai' element={<AccessInventory/>}/>
                        <Route path='/monitor/staging' element={<Staging/>}/>
                        <Route path='/monitor/serviceCatalog' element={<ServiceCatalog/>}/>
                        <Route path='/monitor/catalog' element={<Catalog/>}/>
                        <Route path='/monitor/speed' element={<MaxSpeed/>}/>
                        <Route path='/monitor/sigma' element={<Sigma/>}/>
                        <Route path='/test/service' element={<ServiceExecution/>}/>
                        <Route path='/management/resource' element={<Resource/>}/>
                        <Route path='/management/property' element={<Property/>}/>
                        <Route path='/management/env' element={<EnvInfo/>}/>
                        <Route path='/management/cache' element={<Cache/>}/>
                        <Route path="/visitation-swagger" element={<Swagger />} />
                        <Route path='/transaction/details' element={<Details />} />
                        <Route path='/transaction/delta' element={<DeltaInfo />} />
                        <Route path='/transaction/retry' element={<Retry />} />
                    </Routes>
                </Nav>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Login/>
            </UnauthenticatedTemplate>
        </BrowserRouter>
    );
// }
    /*
    return
        <div>{error ? <ErrorBody errorMessage={error}/> : <Loading/>}</div>
    ;
    */
}


export default App;
